




























































































































import Vue from 'vue';
import * as Api from '@/api/dictionaryList';
import moment from 'moment';
const columns = [
  {
    title: '资源id',
    dataIndex: 'triggerId',
    key: 'triggerId',
    align: 'center',
    width: '240px',
    ellipsis: true,
  },
  {
    title: '资源名称',
    dataIndex: 'resourceName',
    key: 'resourceName',
    align: 'center',
  },
  {
    title: '描述',
    dataIndex: 'oprateDescription',
    key: 'oprateDescription',
    align: 'center',
    width: '200px',
    ellipsis: true,
  },
  {
    title: '事件类型',
    dataIndex: 'eventType',
    key: 'eventType',
    align: 'center',
    scopedSlots: { customRender: 'eventType' },
    ellipsis: true,
  },
  {
    title: '事件编码',
    dataIndex: 'eventCode',
    key: 'eventCode',
    align: 'center',
    width: '100px',
    ellipsis: true,
  },
  {
    title: '事件名称',
    dataIndex: 'eventName',
    key: 'eventName',
    align: 'center',
  },
  {
    title: '事件脚本',
    dataIndex: 'eventScript',
    key: 'eventScript',
    align: 'center',
  },
  {
    title: '排序',
    dataIndex: 'extend1',
    key: 'extend1',
    align: 'center',
  },
  {
    title: '是否重试',
    dataIndex: 'needPersist',
    key: 'needPersist',
    align: 'center',
    scopedSlots: { customRender: 'needPersist' },
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];
const dataSource = [];
export default Vue.extend({
  name: 'dictList',
  data() {
    return {
      tableLoading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      formState: {
        resourceName: '',
        triggerId: '',
      },
      dataSource,
      columns,
      formVisible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      loading: false,
      type: false,
      form: {
        resourceName: '',
        triggerId: '',
        oprateDescription: '',
        eventType: '',
        eventCode: '',
        eventName: '',
        eventScript: '',
        needPersist: '',
        extend1: '',
      },
      rules: {
        resourceName: [
          {
            required: true,
            message: '请输入资源名称',
            trigger: 'change',
          },
        ],
        triggerId: [
          {
            required: true,
            message: '请输入资源ID',
            trigger: 'change',
          },
        ],
        eventType: [
          {
            required: true,
            message: '请输入事件类型',
            trigger: 'change',
          },
        ],
        eventName: [
          {
            required: true,
            message: '请输入事件名称',
            trigger: 'change',
          },
        ],
        needPersist: [
          {
            required: true,
            message: '请选择是否重试',
            trigger: 'change',
          },
        ],
      },
      deleteVisible: false,
      deleteId: 0,
    };
  },
  created() {
    this.handleSubmit();
  },
  methods: {
    moment,
    // 查询按钮
    handleSubmit() {
      this.pagination.current = 1;
      this.getDictList();
    },
    getDictList() {
      const param = {
        page: this.pagination.current,
        limit: this.pagination.pageSize,
        triggerId: this.formState.triggerId,
        resourceName: this.formState.resourceName,
        orderField: 'REC_DATE',
        order: 'desc',
      };
      this.tableLoading = true;
      Api.searchEventValue(param)
        .then((res) => {
          const data = res;
          if (data.status === 200) {
            this.dataSource = res.result.list;
            this.pagination.total = res.result.total;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    reset() {
      this.formState.triggerId = '';
      this.formState.resourceName = '';
      this.handleSubmit();
    },
    // 主列表翻页控制
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDictList();
    },
    // 新增按钮
    newForm(record) {
      this.formVisible = true;
      if (record === 0) {
        this.type = false;
        this.form.triggerId = '';
        this.form.resourceName = '';
        this.form.eventType = '';
        this.form.eventCode = '';
        this.form.eventName = '';
        this.form.eventScript = '';
        this.form.oprateDescription = '';
        this.form.needPersist = '';
        this.form.extend1 = '';
      } else {
        this.form = record;
        if (record.needPersist === false) {
          this.form.needPersist = 'false';
        }
        if (record.needPersist === true) {
          this.form.needPersist = 'true';
        }
        this.type = true;
      }
    },
    // 事件新增表单提交
    onSubmit() {
      (this.$refs.ruleForm as HTMLFormElement).validate((valid) => {
        if (valid) {
          const params = {
            oprateDescription: this.form.oprateDescription,
            triggerId: this.form.triggerId,
            resourceName: this.form.resourceName,
            eventType: this.form.eventType,
            eventCode: this.form.eventCode,
            eventName: this.form.eventName,
            eventScript: this.form.eventScript,
            needPersist: this.form.needPersist,
            extend1: this.form.extend1,
          };
          if (this.type === false) {
            Api.eventSave(params)
              .then((res) => {
                if (res) {
                  this.loading = true;
                  this.handleSubmit();
                  this.formVisible = false;
                  (this.$refs.ruleForm as HTMLFormElement).resetFields();
                }
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            const param = {
              ...params,
              sequenceNbr: this.form['sequenceNbr'],
            };
            Api.editEventValue(param)
              .then((res) => {
                if (res) {
                  this.loading = true;
                  this.handleSubmit();
                  this.formVisible = false;
                  (this.$refs.ruleForm as HTMLFormElement).resetFields();
                }
              })
              .finally(() => {
                this.loading = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    cancel() {
      this.formVisible = false;
      (this.$refs.ruleForm as HTMLFormElement).resetFields();
      this.handleSubmit();
    },
    // 删除事件
    deleteEvent(record) {
      this.deleteVisible = true;
      this.deleteId = record.sequenceNbr;
    },
    deleteConfirm() {
      Api.deleteEventValue(this.deleteId)
        .then((res) => {
          if (res) {
            this.loading = true;
            this.deleteVisible = false;
            this.handleSubmit();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
