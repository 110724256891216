/*
 * @Descripttion:
 * @Author: wuxinxin
 * @Date: 2022-04-24 14:58:28
 * @LastEditors: wuxinxin
 * @LastEditTime: 2022-05-06 16:33:27
 */
import http from '@/utils/http';

//  查询字典目录
export function searchDict(formdata: any): any {
  return http.get(`/sys-resource/1/sysDictionary`, formdata);
}
//  保存字典目录
export function saveDict(formdata: any): any {
  return http.post(`/sys-resource/1/sysDictionary`, formdata);
}
//  修改字典目录
export function editDict(formdata: any): any {
  return http.put(`/sys-resource/1/sysDictionary`, formdata);
}
//  删除字典目录
export function deleteDict(formdata: any): any {
  return http.delete(`/sys-resource/1/sysDictionary`, formdata);
}
//  根据字典目录CODE分页查询字典值
export function searchDictValue(formdata: any): any {
  return http.get(`/sys-resource/1/sysDictionaryEntry`, formdata);
}
//  保存字典值
export function dictionaryEntry(formdata: any): any {
  return http.post(`/sys-resource/1/sysDictionaryEntry`, formdata);
}
//  修改字典值
export function editDictValue(formdata: any): any {
  return http.put(`/sys-resource/1/sysDictionaryEntry`, formdata);
}
//  删除字典值
export function deleteDictValue(formdata: any): any {
  return http.delete(`/sys-resource/1/sysDictionaryEntry`, formdata);
}
//  查询事件列表
export function searchEventValue(formdata: any): any {
  return http.get(`sys-resource/1/sysEventConfigResourceV1/page`, formdata);
}
//  保存事件
export function eventSave(formdata: any): any {
  return http.post(`sys-resource/1/sysEventConfigResourceV1`, formdata);
}
//  修改事件值
export function editEventValue(formdata: any): any {
  return http.put(`sys-resource/1/sysEventConfigResourceV1`, formdata);
}
//  删除事件值
export function deleteEventValue(formdata: any): any {
  return http.delete(
    `sys-resource/1/sysEventConfigResourceV1/${formdata}`,
    formdata
  );
}
